import { domReady } from './ready.js'

domReady(() => {
    _.each(
        document.querySelectorAll('.inline-edit'),
        (el) => {
            let input = el.querySelector('.form-control'),
                editBtn = el.querySelector('.edit-btn'),
                cancelBtn = el.querySelector('.cancel-btn'),
                origValue = input.value

            let editMode = () => {
                input.removeAttribute('disabled')
                editBtn.classList.remove("collapsed")
                el.classList.remove("disabled")
            }

            let disabledMode = () => {
                input.setAttribute('disabled', 'disabled')
                editBtn.classList.add("collapsed")
                el.classList.add("disabled")
            }

            let save = () => {
                return axios({
                    method: 'put',
                    url: el.dataset.updateUri,
                    data: {
                        'remove_date': input.value
                    }
                })
            }

            editBtn.addEventListener('click', (event) => {
                event.preventDefault()
                if (input.hasAttribute('disabled')) {
                    editMode()
                } else {
                    // save
                    disabledMode()
                    save()
                    .then(() => {
                        origValue = input.value
                        //TODO: toast
                    })
                    .catch((error) => {
                        //TODO: toast
                        console.log(error);
                    })
                }
            })

            cancelBtn.addEventListener('click', (event) => {
                event.preventDefault()
                input.value = origValue
                disabledMode()
            })
        }
    )
})